::-webkit-scrollbar { width: 10px }
  
::-webkit-scrollbar-thumb { background: var(--theme-color-light) }

::-webkit-scrollbar-track-piece{ background-color: var(--white-color ) }

::-webkit-scrollbar-corner { background: none }

@media (max-width: 960px) {
    ::-webkit-scrollbar { display: none; }
}