@import './scrollbar.scss';

:root {
	// Constants
	--theme-color: #000559;
	--theme-color-light: #AEAEAE;
	--theme-color-lighter: #c9c6c6;
	--theme-secondary: #edf1f2;
	--white-color: white;
	--font-size-m: 24px;
	--font-size-l: 30px;

	// Dimensions
	--header-height: 60px;
	--sidebar-width: 225px;

	--error-font-size: 12px;
}

html,
body {
	background-color: var(--theme-color);
	padding: 0;
	margin: 0;
	max-height: 100vh;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.flex {
	display: flex;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.justifyCenter {
	justify-content: center;
}

.alignCenter {
	align-items: center;
}

.spaceAround {
	justify-content: space-around;
}

.spaceBetween {
	justify-content: space-between;
}

.lineBreak {
	background-color: var(--theme-color);
	height: 1px;
}

.lineBreakSecondary {
	background-color: var(--theme-secondary);
	height: 1px;
}

.fullWidth {
	width: 100%;
}

.cardBorders {
	padding: 20px;
	margin-bottom: 10px;
	border: 1px solid var(--theme-color);
}

.cardTitle {
	font-size: 18px;
}

.cardSubTitle {
	font-size: 12px;
}

.cardAction {
	font-size: 26px;
	margin: 0 5px;
	cursor: pointer;
}

.grid {
	display: grid;
}

.textAlignCenter {
	text-align: center;
}

.errorMessage {
	color: red;
	margin: 0;
	font-size: 12px;
}

.error{
	font-size: var(--error-font-size);
	color: red;
}

.moduleContainer {
	max-width: 99%;
	margin: 0 auto;
	min-height: 100%;
}

.moduleSearchBar{
	margin: 10px auto;
}

.moduleContainerGutter{
	max-width: 960px;
}

.deleteMessage {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: inherit;
}

.contentSection{
	padding: 20px 25px;
	border: 1px solid var(--theme-color-light);
	margin-bottom: 40px;

	.contentSectionTitle{
		font-size: var(--font-size-m);	
		margin: 0 auto 20px;
	}
}