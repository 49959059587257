.modalContainer{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);    

    .modalContent{
        background-color: var(--theme-secondary);
        max-height: 70vh;
        width: 30vw;
        
        .modalContentHeader{
            text-align: center;
            background-color: var(--theme-color);
            color: white;
            min-height: 60px;

            h2{
                margin: 10px 0;
                font-weight: 100;
                font-size: 24px;
            }
        }

        .modalContentBody{
            width: inherit;
            min-height: 100px;
            max-height: 50vh;
            overflow-y: auto;

            .mcbContainer{
                width: 85%;
                max-width: 500px;
                margin: 10px auto;
            }
        }

        .modalContentFooter{
            background-color: var(--theme-color);
            justify-content: space-around;
            
            .modalActions{

                justify-content: center;

                .modalActionButton{
                    width: 120px;
                    margin: 15px;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .modalContainer{
        .modalContent{
            max-height: 80vh;
            width: 80vw;

            .modalContentHeader{}
    
            .modalContentBody{}
    
            .modalContentFooter{
                .modalActions{
                    width: 90%;
                    margin: 0 auto;
                    flex-direction: column;

                    .modalActionButton{
                        width: 100%;
                        margin: 10px auto;
                    }
                }
            }
        }
    }
}