.LayoutContainer{
    display: flex;
    min-height: calc(100vh - var(--header-height));
    width: 100%;

    margin-top: var(--header-height);
    background-color: var(--theme-secondary);
    
    .layoutWrapper{
        flex: 1; // Used to make the wrapper's width contain all the remaining parent container
        padding: 0 50px;

        .layoutHeader{
            justify-content: space-between;
            margin: 30px auto;
            min-height: 50px;

            .layoutTitle{
                h1{ width: inherit; margin: 0; text-align: center;}

                .sidebarDrawerButton{ display: none }
            }

            .layoutActionButtons{
                .layoutActionButton{ width: 120px }
            }
        }

        .layoutFilter{
            display: flex;

            .formField{
                max-width: 250px;
                margin-right: 20px;
            }
        }

        .layoutFilterDropdown{ display: none}

        .filterButton{ display: none}

        .content{
            overflow-y: auto;
            scrollbar-gutter: stable;
            height: calc(100vh - (50px + var(--header-height)) * 2);   
        }
    }
}

@media (max-width: 960px) {
    .LayoutContainer{
        .layoutWrapper{
            padding: 0 30px;

            .layoutHeader{
                margin: 10px auto;
                .layoutTitle{

                    h1{ font-size: 28px }

                    .sidebarDrawerButton{
                        display: block;
                        padding-right: 10px;
                        font-size: 24px;
                        margin-top: 6px;
                    }
                }

                .layoutActionButtons{
                    .layoutActionButton{ width: 90px}
                }
            }

            .layoutFilter{
                display: none;
            }

            .layoutFilterDropdown{
                display: flex;
                flex-direction: column;
                .formField{
                    max-width: 100%;
                    margin: auto;
                }
            }

            .filterButton{
                display: block;
                margin: 0 auto 20px;
                min-height: 0;
                height: 35px;
                
            }

            .content{
                overflow-y: auto;
                height: 100vh;   
            }
        }
    }
}
