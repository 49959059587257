.dataSourceCodesContainer{
    .dscTable{
        width: 100%;
        overflow-x: scroll;
        border-spacing: 5px;

        th{ font-weight: normal;}

        .dsctRow{
            width: 100%;
            text-align: center;

            td { select { text-align: center } }

            .dsctrCancel { cursor: pointer }
        }
    }
}
