.headerContainer{
    height: var(--header-height);
    z-index: 1;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--theme-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    .logoContainer{
        &:hover{ cursor: pointer }
        padding: 0 10px;
    }

    .headerMenu{
        display: flex;
        margin: auto 50px auto 0;

        .navItem{
            padding: 20px;
            transition: all 100ms ease-in-out;
            
            &:hover{
                cursor: pointer;
                transform: scale(1.05);
            }
        }
    }

    .headerDrawer{display: none}
}

.headerDrawerMenu{ display: none}


@media (max-width: 960px) {
    .headerContainer{
        flex-direction: row-reverse;
        justify-content: center;
        .headerMenu{ display: none }

        .headerDrawer{
            display: flex;
            position: fixed;
            left: 0;
            top: var(--header-height);
            height: calc(100vh - var(--header-height));
            background-color: var(--theme-color);
            width: 40vw;
            max-width: 400px;
            flex-direction: column;
            border-top: 1px solid white;
            padding: 20px 0;

            .navItem{
                margin: 10px auto;
            }
        }
    }

    .headerDrawerMenu{ 
        position: fixed;
        top: 0;
        left: 2vw;
        min-height: var(--header-height);
        width: 40px;
        z-index: 1;

        &:hover{ cursor: pointer}

        .icon{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 30px;

            padding: 0 10px;
            .drawerMenuStroke{
                background-color: white;
                width: 30px;
                height: 4px;
            }
        }

        
    }
}