.notificationsWrapper{
    position: absolute;
    z-index: 2;
    margin-top: var(--header-height);   
    left: 50%;
    transform: translate(-50%, -50%);

    .notificationCard{
        position: relative;
        height: 80px;
        border: 1px solid var(--theme-color);
        width: 600px;
        margin: 20px auto;
        color: var(--theme-secondary);
        transition: all ease-in 0.25s;
        animation: showUp 0.25s ease-in;
    }

    .success{ background-color: green }

    .error{ background-color: red }

    .disappear{
        filter: opacity(0%)
    }

    @keyframes showUp {
        from {
            filter: opacity(0);
        }
        to{
            filter: opacity(1)
        }
    }
}
