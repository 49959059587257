:root{
    --border-format: 0.5px solid grey;
}


.tabsContainer{
    width: 100%;
    padding-top: 20px;
    height: inherit;
    
    .tabs{
        width: inherit;
        display: flex;
        justify-content: space-around;
        min-height: 50px;
        align-items: center;
        text-align: center;

        .tab{
            flex: 1;
            min-height: inherit;
            border-bottom: var(--border-format);
            padding: 0 5px;
            cursor: pointer;
        }

        .selected{
            border: var(--border-format);
            border-bottom: none;
        }
    }

    .tabContent{
        flex: 1;
        border: var(--border-format);
        border-top: none;
        padding: 20px;
        overflow-y: auto;
    }
}

@media (max-width: 960px) {
    .tabsContainer{
        margin-top: 10px;

        .tabs{    
            .tab{
                font-size: 14px;
            }
        }
    
        .tabContent{
            padding: 10px;
        }
    }
}