.formContainer{
    .formActions{
        margin-top: 20px;
        .formAction{
            width: 120px;
            margin: auto 20px;
        }
    }
}

@media (max-width: 960px) {
    .formContainer{
        .formActions{
            flex-direction: column-reverse;
            .formAction{
                width: 100%;
                margin: 5px auto;
            }
        }
    }
}