.inputBoxContainer{
    margin: 10px 0;
    justify-content: space-between;
    width: 100%;

    .input{
        margin: 5px 0;
        height: 40px;
        border: none;
        border-radius: 0.3rem;
        padding: 0 10px;
        width: 100%;
        
        &:focus{
            border: none;
            outline: none;
        }
    }

    .readOnly{
        background-color: #fbfbfb;
        color: #9d9d9d;
        cursor: not-allowed;
        caret-color: black;
        height: 40px;
    }
}

.label{
    margin: 5px 0;
    font-weight: 100;
    font-size: 12px;
}