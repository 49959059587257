.wsUpdateRuleTable{
    .freqInputBox{
        margin: auto;

        select{
            margin-left: auto;
            margin-right: auto;
            width: 140px;
            text-align: center;
        }
    }
}


@media (max-width: 960px) {
    .wsUpdateRuleTable{
        font-size: 12px;

        .freqInputBox{
            select{
                width: 80px;
            }
        }
    }
}