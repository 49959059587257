.loginContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 80%;
    max-width: 850px;
    min-height: 100vh;
    
    .appLogo{
        .logoImage{
            width: 50vw;
            max-width: 500px;
            height: auto;
        }

        p{
            text-align: center;
            font-size: large;
            color: white;
        }
    
    }

    .loginButton{
        border: 1px solid white;
        outline: none;
        width: 180px;
        height: 40px;
        background-color: var(--theme-color);
        border-radius: 2rem;
        color: white;

        &:hover{
            border: 1px solid white;
            background-color: var(--theme-color);
            color: white;
            cursor: pointer;
        }
    }
}


@media (max-width: 600px) {
    .loginContainer{
        .appLogo{
            img{
                max-height: 180px;
            }    
        }
    }
}
