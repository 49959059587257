.checkBoxContainer{
    margin: 10px 0;

    .label{
        margin: 5px 0;
        font-weight: 100;
        font-size: 12px;
    }

    .options{
        width: inherit;
        .option{
            padding: 10px;
            .input{        
                &:focus{
                    border: none;
                    outline: none;
                }
            }
        
            .readOnly{ cursor: not-allowed}

            .optionLabel{
                margin-left: 20px;
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 400;
            }
        
            .error{
                font-size: var(--error-font-size);
                color: red;
            }
        }
    }
}