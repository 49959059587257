.fileInputContainer{
    display: flex;
    flex-direction: column;

    .fileInputLabel{
        margin: 5px 0;
        font-weight: 100;
        font-size: 20px;
        width: inherit;
        text-align: center;
    }
    
    .fileInput{
        border: none;
        outline: none;
        margin: 10px auto;
        width: inherit;
                
        &::-webkit-file-upload-button {
            cursor: pointer;
            width: 100%;
            height: 40px;
            background-color: var(--theme-color);
            border-radius: 0.3rem;
            color: var(--theme-secondary);
            border: 1px solid var(--theme-secondary);
            margin-right: 20px;
            padding: 5px 0;
            
        }        
    }
}