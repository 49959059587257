.loaderContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    .loader {    
        border: 16px solid var(--theme-color-light); /* Light grey */
        border-top: 16px solid var(--theme-color); /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

}
