.rateClassProperties{
    .label{
        margin: 15px auto;
    }

    .propertiesList{
        .rateClassProp{
            display: grid;
            grid-template-columns: 1fr 1fr 2fr;
            align-items: center;

            .propertyHeader{
                font-weight: 400;
            }

            .propertyController{
                select{
                    text-align: center;
                }
            }
        }
    }
}

.mrsContainer{
    .tools{
        display: flex;
        width: 100%;
        
        .selectBoxContainer{
            width: 48%;
            margin-right: 20px;
        }
    }

    .meterReadSchedules{
        width: 100%;
        display: block;
        overflow-x: scroll;
        text-align: center;
        border-spacing: 0;
        padding-bottom: 10px;
    
        .mrsHeader{
            th{
                height: 50px;
                min-width: 110px;
                border: 1px solid;
            }
            
        }
    
        .mrsRecord{
            td{
                height: 50px;
                border: 1px solid;
            }
        }
    }
}
