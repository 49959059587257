.collapsed{
    &:hover{
        background-color: var(--theme-color-light);
    }
}


.datacard-height-small{ height: 300px };

.datacard-height-medium{ height: 400px };

.datacard-height-large{ height: 500px };


.dataCardContainer{
    .dccHeader{
        justify-content: space-between;
        cursor: pointer;

        .dcchText{
            h2{
                font-size: 20px;
                font-weight: 400;
                margin: 0;
            }

            h3{
                font-size: 16px;
                font-weight: 100;
                margin: 0;
            }
        }

        .dcchActions{
            .dcchaAction{
                font-size: 25px;
                margin-left: 10px;

                &:hover{ cursor: pointer;}
            }
        }
    }
    .dccExpand{
        .dccExpandDivide{
            margin: 10px auto;
            height: 0.25px;
            background-color: black;
        }

        .dccExpandContent{ 
            overflow-y: auto;
        }
    }
}

@media (max-width: 960px) {
    .dataCardContainer{
        .dccHeader{    
            .dcchText{
                h2{
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                }
    
                h3{
                    font-size: 12px;
                    font-weight: 100;
                    margin: 0;
                }
            }
    
            .dcchActions{
                .dcchaAction{
                    font-size: 20px;
                    margin-left: 7px;
    
                    &:hover{ cursor: pointer }
                }
            }
        }
    }   
}