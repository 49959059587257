.tagsContainer{
    .tagBubbles{
        .tagBubble{
            background-color: var(--theme-color);
            padding: 5px 10px;
            color: var(--theme-secondary);
            border-radius: 1rem;
            margin-right: 10px;

            .tagText{
                padding: 0 10px;
            }
        }
    }
}