.filterBar{
    width: 100%;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    .fbField{
        margin-right:10px;
        flex: 1;
    }
}