.userReactivation{
    width: 100%;
    padding: 20px 0;
    text-align: center;

    .userDeactivationNotice{
        width: inherit;
        margin-bottom: 20px;
        text-align: center;
        font-size: 16px;
    }

    .reactiveButton{
        width: 50%;
        margin: auto;
    }
}



@media (max-width: 960px) {
    .userReactivation{    
        .reactiveButton{
            width: 100%;
            margin: auto;
        }
    }
}