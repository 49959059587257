.selectBoxContainer{
    margin: 10px 0;
    

    .label{
        margin: 5px 0;
        font-weight: 100;
        font-size: 12px;
    }

    .selectedOption{
        span{
            margin-left: 15px;
            cursor: pointer;
        }

        input{
            background-color: transparent;
            color: black;
        }
    }

    select{
        margin: 5px 0;
        height: 40px;
        border: none;
        border-radius: 0.3rem;
        padding: 0 10px;
        text-overflow: ellipsis;
        width: 100%;

        cursor: pointer;

        option{
            // width: 100%;
            height: 40px;
            border: none;
            font-size: inherit;
        }
    }
}