.sidebarContainer{
    background-color: var(--theme-color-light);
    width: var(--sidebar-width);
    min-height: 100%;

    .reroute{
        margin: 10px auto;
        width: 100%;
        border-radius: 1rem;
        padding: 10px;
        text-align: center;

        &:hover{ cursor: pointer }

        span{ width: 100% }

        .rerouteIcon{
            position: absolute;
            font-size: 20px;
            align-items: flex-start;
        }
    }

    .navigation{
        // border-bottom: 1px solid var(--theme-color);
        .navOption{
            padding: 5px 0;
            margin-bottom: 5px;
            cursor: pointer;

            &:hover{
                background-color: var(--theme-secondary);
            }

            .navOptionContent{
                padding: 0 10px;
                span{
                    padding-left: 10px;
                }
            }
        }
    }
}

.sidebarDrawer{ display: none}


@media (max-width: 960px) {
    .sidebarContainer{display: none }

    .sidebarDrawer{
        display: block;
        position: fixed;
        background-color: var(--theme-color-light);
        width: var(--sidebar-width);
        min-height: 100%;
        z-index: 1;

        .reroute{
            margin: 10px auto;
            width: 100%;
            border-radius: 1rem;
            padding: 10px;
            text-align: center;

            &:hover{ cursor: pointer }

            span{ width: 100% }

            .rerouteIcon{
                position: absolute;
                font-size: 20px;
                align-items: flex-start;
            }
        }

        .navigation{
            // border-bottom: 1px solid var(--theme-color);
            .navOption{
                padding: 5px 0;
                margin-bottom: 5px;
                cursor: pointer;
    
                &:hover{
                    background-color: var(--theme-secondary);
                }
    
                .navOptionContent{
                    padding: 0 10px;
                    span{
                        padding-left: 10px;
                    }
                }
            }
        }

    }
}