.spinnerContainer {
    height: inherit;

    .spinner {
        border-radius: 50%;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .spinnerText{
        padding: 10px 0 0;
        font-size: 16px;
    }
}

.spinnerSizeS {
    border: 4px solid var(--theme-color-light);
    /* Light grey */
    border-top: 4px solid var(--theme-color);
    /* Blue */
    width: 40px;
    height: 40px;
}

.spinnerSizeM {
    border: 8px solid var(--theme-color-light);
    /* Light grey */
    border-top: 8px solid var(--theme-color);
    /* Blue */
    width: 80px;
    height: 80px;
}

.spinnerSizeL {
    border: 16px solid var(--theme-color-light);
    /* Light grey */
    border-top: 16px solid var(--theme-color);
    /* Blue */
    width: 120px;
    height: 120px;
}