.intervalCurveCardContainer{
    border: 0.5px solid grey;
    padding: 10px;
    margin: 15px 0;
    display: flex;
    align-items: center;

    .icccCurveProperties{
        width: 90%;
        display: grid;
        grid-auto-flow: column;

        .curveProperty{
            max-width: 140px;
            text-align: center;
            margin: 5px 0;
    
            .curvePropertyKey{
                margin: 5px 0;
                font-weight: 100;
                font-size: 12px;
            }
    
            .curvePropertyValue{
                font-size: 12px;
            }
        }
    }
    
    .icccActions{
        width: 10%;

        .icccaAction{
            cursor: pointer;
        }
    }
}


@media (max-width: 960px) {
    .intervalCurveCardContainer{
        flex-direction: column;

        .icccCurveProperties{
            width: 100%;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: row;
        }
        
        .icccActions{
            width: 100%;
            justify-content: center;
            align-items: center;
            margin: 15px auto 0;
        }
    }            
}